@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.2/font/bootstrap-icons.css");

@font-face {
    font-family: Raleway;
    src: url('/public/fonts/Raleway-VariableFont_wght.ttf');
}

*{
    font-family: Raleway;
}

body{
    background: rgb(103,131,210);
    background: linear-gradient(117deg, rgba(103,131,210,1) 0%, rgba(136,105,186,1) 100%);
}

.elemento {
    border: 1px solid #dfdfdf;
    background-color: #fff;
    padding-block: 10px;
    padding-inline: 15px;
}

.PhoneInputInput{
    border: none;
}

.PhoneInputInput:focus-visible{
    outline: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

.fondoDegradado{
    background: rgb(0,122,168);
    background: linear-gradient(249deg, rgba(0,122,168,1) 0%, rgba(29,174,223,1) 100%);
}

.alert{
    border-radius: 15px !important;
    background-color: rgba(249, 37, 44, 0.133) !important;
}

iframe{
    margin-top: 15px;
}

.inputOTP:focus{
    outline: none;
}

.react-switch-selector-option-unselected{
    color: white;
}

.react-switch-selector-option-selected{
    color: #1DAEDF;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hideScrollbar::-webkit-scrollbar {
    display: none;
  }

.hideScrollbar {
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */
}

.boxShadowLigero {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.recompensaActiva{
    height: 120px;
    width: 100%;
    background-color: #A62F6E;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 15px;
    padding: 10px;
    color: white;
    border: none;
}