.tarjetaCliente{
    border: none;
    background: linear-gradient(45deg, rgba(56, 62, 83, 0.91) 0% 20%, rgba(56, 62, 83, 0.94) 20% 40%, rgba(56, 62, 83, 0.97) 40% 60%, rgba(56, 62, 83, 1) 60%);
    border-radius: 15px;
    padding: 10px;
    color: white;
    width: 100%;
    max-width: 350px;
    height: 180px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;
}

.tarjetaCliente .logo {
    max-width: 40px;
}

.btnMostrarQR{
    border-radius: 20px;
}

.modalQR .modal-content{
    border: none;
    background-color: transparent;
}

.modal-backdrop.show {
    opacity: 1;
    backdrop-filter: blur(4px);
}

.modal-backdrop {
    background-color: rgba(0, 0, 0, 0.4);
}

.modalRecompensa .modal-content{
    border-radius: 15px;
    background-color: #F0F9F7;
    border: none;
}

.modalRecompensa{
    padding-inline: 10px !important;
}

.tarjetaRecompensas{
    background-color: #383E53;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 15px;
    padding: 10px;
}

.col5{
    width: 20%;
}